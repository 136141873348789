'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

// Get access to the form widget api.
var formWidget = require('form-widget');

var showThankYou = function() {

	console.log('showThankYou....');

	// The store friendly url conversion removes spaces from url params.
	// Add a pipe delimiter for spaces.
	var headerText = formWidget.submissionHeaderText.split(' ').join('|');
	var msgText = formWidget.submissionMsgText.split(' ').join('|');


	/*
		this hack is related to how some store sites load with '/store' and others
		load out of root '/'. let's call this the store's 'root directory'

		the hack references the global variable serverVars to ensure the (successful
		form submission) redirect respects that root directory.
	*/
	var dir = '';

	if (typeof serverVars === 'object') {
		dir = window.serverVars.store_frontend_directory;
	}

	$.mobile.changePage(encodeURI(dir + "/?load=message&msgKey=formWidgetSuccess&steps=-1&hdr=" + headerText + "&msg=" + msgText + window.mobile.globals.previewMode));

};

var showClosedMsg = function(markup) {

	formWidget.form.html(markup);

};

module.exports = {

	init: function() {

		formWidget.init({
			elId: 'formWidget'/*,
			forceOneColMode: true*/
		});

		formWidget.form.off('.formWidget');

		formWidget.form.on('fbWidget-form-submit-success.formWidget', showThankYou);

		formWidget.form.on('fbWidget-form-expired fbWidget-form-at-submission-limit', function(e) {

			showClosedMsg(e.markup);

		});

	},

	destroy: function() {

		formWidget.destroy();

	}

};
