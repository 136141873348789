'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var api = {};

var state = {};

var data = {};

var globals = mobile.globals;

var dom = {
	document: $(document)
};

var addEventHandlers = function(){

	dom.document.off('.form');

	dom.document.on('focus.form', '.date input, .time input', function(){
		$(this).parents('.control').addClass('focused');
	});

	dom.document.on('blur.form', '.date input, .time input', function(){
		if(!$(this).val().length){
			$(this).parents('.control').removeClass('focused');
		}
	});

	dom.document.on('change.form', '#date', function(){

		var $control = $(this).parents('.control'),
			val = $control.val();

		val.length ? $control.attr('data-has-date', true) : $control.attr('data-has-date', false);

	});

	dom.document.on('vclick.form', 'form.custom-form input[type=submit]', function(e){

		// "custom forms" are created via controlpanel hacks and shouldn't get
		// picked up by any mobile system level handlers
		e.stopImmediatePropagation();

	});

	dom.document.on('vclick.form', 'form:not(#pageLockedForm) input[type=submit]', function(){

		$(this).closest('form').validate({

			rules: {

				email: {
					required: true,
					email: true
				}

			},

			highlight: function(el){

				var $this = $(el);
				$this.parents('.date,.time').addClass('error');
				$this.addClass('error');

			},

			unhighlight: function(el){

				var $this = $(el);
				$this.removeClass('error');

			},

			submitHandler: function(form){

				var $form = $(form);
				var backSteps = typeof $form.attr('data-back-steps') !== 'undefined' ? $form.attr('data-back-steps') : -1;
				var msgKey = typeof $form.attr('data-msg-key') !== 'undefined' ? $form.attr('data-msg-key') : 'contactSuccess';

				/*
					this hack is related to how some store sites load with '/store' and others
					load out of root '/'. let's call this the store's 'root directory'

					the hack references the global variable serverVars to ensure the (successful
					form submission) redirect respects that root directory.

					bare in mind, serverVars only exists on the store platform so we must check
					for the existance of serverVars before we reference it
				*/
				var dir = '';

				if(typeof serverVars === 'object') {
					dir = serverVars.store_frontend_directory;
				}

				$.when(processFormSubmission(form)).then(function(){

					$.mobile.changePage(dir + '/?load=message&msgKey=' + msgKey + '&steps=' + backSteps + '&' + globals.previewMode);

				});

			}

		});
	});

};

var getParsedFormData = function($form){

	var paramObj = {};

	$.each($form.serializeArray(), function(_, key) {
		paramObj[key.name] = key.value;
	});

	return paramObj;

};

var formatFormDataForStoreActionPage = function(formData){

	var json = {};

	json.fieldNames = [];
	json.fieldValues = [];

	for(var key in formData){

		if(key.indexOf('field_') === 0){

			json.fieldNames.push(key);
			json.fieldValues.push(formData[key]);

		}

	}

	console.log('yo', JSON.stringify(json));
	formData.json = JSON.stringify(json);

	return formData;

};

var processFormSubmission = function(form){

	var $form = $(form),
		formData = getParsedFormData($form);

	if(formData.formType === 'contact-store'){
		formData = formatFormDataForStoreActionPage(formData);
	}

	return $.ajax({
		url: $form.attr('data-action'),
		method: 'post',
		data: formData
	});

};

var processCommentSubmission = function(formTarget, formAction){

	console.log('processCommentSubmission');

	var $form = $('form');

	$form.attr('action', formAction);

	$form.validate({

		rules: {

			email: {
				required: true,
				email: true
			}

		},

		highlight: function(el){

			$(el).closest('div,textarea').addClass('error');

		},

		unhighlight: function(el){

			$(el).closest('div,textarea').removeClass('error');

		},

		submitHandler: function(){

			$.ajax({
				url: $form.attr('action'),
				method: 'post',
				data: getParsedFormData($form),
				complete: function(data){
					window.location.reload();
				}
			});

		}

	});

	$form.submit();

};

// intentionally created as global to match how the desktop markup onclick
// handler is setup.
// this is for the comment form...

/******
	CHANGE THIS TO USE A DIFFERENT METHOD, NOT GLOBAL
	by stripping onclick and setting up custom handler in this file
******/
window.changeform = function(formTarget, formAction){

	var isCommentSubmission = formAction.indexOf('blog_detail_action') >= 0,
		isPageAccessSubmission = formAction.indexOf('access_action') >= 0;


	if(isCommentSubmission) {
		processCommentSubmission(formTarget, formAction);
	}

};

api.init = function(){

	addEventHandlers();

};

api.destroy = function() {

	dom.document.off('.form');

};

module.exports = api;