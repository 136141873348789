'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var pageBuilderWidget = require('page-builder-widget');

var dom;
var options;
var pageBuilderWidgetId;

var onPageBuilderWidgetSlideshowRequested = function(e, data) {

	var urlParams = 'instanceId=' + data.builderFieldInstanceId + '&pageBuilderWidgetId=' + pageBuilderWidgetId;

	if (options.urlParams) {

		urlParams += '&' + options.urlParams;

	}

	window.pbizMobile.slideshow.loadSlideshow(data.itemIndex, urlParams);

};

var setupDom = function() {

	dom = {
		pageBuilderWidget: $('#pageBuilderWidget' + pageBuilderWidgetId)
	};

};

var addEventHandlers = function() {

	dom.pageBuilderWidget.on('page_builder_widget_slideshow_requested', onPageBuilderWidgetSlideshowRequested);

};

var create = function(imageGridData, widgetId, opts) {

	console.log('initing page-builder-widget-factory.js', arguments);

	pageBuilderWidgetId = widgetId;
	options = $.extend({}, opts);

	setupDom();
	addEventHandlers();
	pageBuilderWidget.create(pageBuilderWidgetId, imageGridData);

};

module.exports = {
	create: create
};
