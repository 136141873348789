'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var dom = {},

	opts = {
		// sometimes a wrapping element already exists in the dom
		// in some of those cases, we don't want to wrap it again
		wrap: true
	},

	setupDom = function(){

		dom.img = $("img[data-protect]");

	},

	injectStyles = function(){

		var $head = $("head"),
			$style = $("<style/>");

		$style.html(
			".protect { position: absolute; top: 0; right: 0; bottom: 0; left: 0;z-index: 1; }" +
			".protect-wrapper { position: relative; }"
		);

		$head.append($style.wrap("<div/>").parent().html());

	},

	applyProtection = function(){

		dom.img.each(function(i, el){

			var $img = $(el),
				userSettings = $.extend(true, {}, opts);


			$.extend(userSettings, $img.data("protect"));

			console.log(userSettings.wrap);

			if(userSettings.wrap){

				$img.wrap("<div class='protect-wrapper'/>")

			}

			$img.before("<div class='protect'/>");

		});

	},

	init = function(){

		setupDom();

		injectStyles();

		applyProtection();

	};

$(init);