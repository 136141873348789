// JavaScript Document
/**
 * This file contains the JavaScript Class for opening and manipulating AJAX based modal pop-up window (lighbox)
 *
 * This file contains a single JavaScript Class that provides the said 
 * functionality
 *
 * Language: JavaScript
 * Depenedancies: MooTools 1.11 Core
 *				  utilities.js
 *
 * @package    modalWindow
 * @author     ProjectMiso.net <email@projectmiso.net>
 * @version    1.0
 * @since      August 12, 2010
 * @deprecated N/A
*/

/**
 * Description:
 * The Class must be initiated on a given page or globally. Once initiated the public methods of the 
 * Class can be used to open and manipulate the modal pop-ups. The window is customizable through predefined 
 * CSS class names.
 ****
 *
 * Features:
 * 1. Open with custom width, height, caption and window content
 * 2. Supply HTML text for the  content window 
 * 4. Display a page within the content window as an iframe
 * 5. Invoke JavaScript code after the content loading is complete to add events or processing code for the content window elements
 * 6. Option to remove the close button
 * 7. Option to manually close using JS code
 ****
 *
 * Required CSS classes: (preix is customizable i.e. "store_")
 * modal_Overlay - the style for the alpha overlay covering the parent page.
 * modal_Container - the container holding all window elements
 * modal_iframeLoader - loading image for iframe when source is waiting to load
 * modal_Caption - the caption bar on top of the window
 * modal_Close - the close button
 * modal_Content - the window content panel
 * modal_Cover - the panel that may cover the window content when invoked - i.e. after submitting a form
 ****
 *
 * Initiation Example:
 *
 * 			var myWindow = new modalWindow({ classPrefix: "store_" });
 *
 * Initiation Options - Public Variables:
 *	classPrefix: "store_"			//A String - name for CSS prefix for each of the element
 ****
 *
 * Window Invokation Examples:
 *
 * Supply HTML to the window
 * 		Lightbox.open({width: 425, height: 344, caption: 'My Captions......', iframe: false, text: "Hellow World!" });
 *
 * 		Required optins are - width, height, text
 * 		Optional options are - close (boolean), iframe (default is false), onLoad (function) and caption (string)
 *
 * Open an URL in an iframe
 * 		Lightbox.open({width: 425, height: 344, caption: 'My Captions......', iframe: true, url: 'http://domain.com' });
 *
 * 		Required optins are - width, height, iframe (true), url
 * 		Optional options are - close (boolean), onLoad (function) and caption (string)
 ****
 *
 * The Invokation Options: the 'options' object
 * @key		width 		integer with custom width - required
 * @key		height		integer with custom height - required 
 * @key		caption		string as the window caption - optional 
 * @key		text		supplied HTML text - if HTML is supplied, only the previous options are required and next options are options
 * @key		url			a local page or remote url - required when content is being loaded from a source file/url
 * @key		iframe		will open the url in an iframe - optional
 * @key		close		Boolean - default is true. If false, will make the close button disappear
 * @key		overlayClose	Boolean - default is true. Allows closing by clicking the background
 *
*/

/**
 * Public Functions:
 *
 * myModal.open({options}); 				//launches the pop-up - see above for details on the "options" object
 * myModal.close(); 						//hides or closes the window
 * myModal.updateContent(text); 			//updates the window content panel with supplied HTML
 * myModal.changeDimension(width, height); 	//updates the width/height of the window. arguments are required
 *
 *
*/

var modalWindow = new Class({
	options:{
		moduleName: 'Lightbox',
		classPrefix: "store_",
		width: 300,
		height: 200,
		showLog: false
	},
	initialize: function(options){
		var self = this;
		this.setOptions(options);				//set options if custom options are being supplied
		
		this.parseOptions(this.options);		//parse optionsand convert them into class properties
		
		this.EL = {};	//store for DOM elements - so they can be garbaged later
		
		//create the initial modal assets
		this.render();
		
		//TODO - delete this
		//$(document.body).addEvent('dblclick', function(event){
		//	self.unload();
		//});
	},
	parseOptions: function(optionsObj, ignoreUndefinedProps){
		if (!optionsObj){ return; } else {
			for (var optionName in optionsObj){
				if (ignoreUndefinedProps && optionsObj[optionName] == undefined){ continue; } else { this[optionName] = optionsObj[optionName]; }
			}
		}
	},
	render: function(){
		var tmp_top = 0, tmp_left = 0, self = this;

		var containerClasses = this.classPrefix + "Container vert-horiz-center";
		
		//create the modal background
		this.EL.overlay = new Element("div", {"id": "modalOverlay", "class": this.classPrefix + "Overlay" }).injectInside(document.body);
		this.EL.overlay.setStyles({ height: window.getScrollHeight(), 'display': 'none' });
		
		//create the modal container
		this.EL.container = new Element("div", {"id": "modalContainer", "class": containerClasses }).injectInside(document.body);
		tmp_top = 50; tmp_left = 50;
		// if (tmp_top < (window.getHeight() - this.height)/2){ tmp_top = (window.getHeight() - this.height)/2; }
		// if (tmp_left < (window.getWidth() - this.width)/2){ tmp_left = (window.getWidth() - this.width)/2; }
		this.EL.container.setStyles({ 
			// height: this.height, width: this.width, top: tmp_top,  display: 'none'
			height: this.height, width: this.width, display: 'none'
		});
		//IE 6 fix
		if (window.ie6){
			this.EL.container.setStyle("position", "absolute");
		}
		
		//create the close button
		this.EL.closeButton = new Element("div", {"id": "modalClose", "href": "", "class": this.classPrefix + "Close" }).injectInside(this.EL.container);
		
		//create the content panel
		this.EL.content = new Element("div", {"id": "modalContent", "class": this.classPrefix + "Content" }).injectInside(this.EL.container);
		this.EL.content.setStyles({ height: this.height, width: this.width });
		
		
		//this.EL.closeButton.addClass(this.classPrefix + "Close");
		this.EL.closeButton.addEvent("click", self.close.bind(self));
		//store event for garbage collection
		PBUtil.garbage.store ('event', self.moduleName, this.EL.closeButton, 'click', self.close); 
		
		//create the caption
		this.EL.caption = new Element("div", {"id": "modalCaption", "class": this.classPrefix + "Caption" }).injectInside(this.EL.container);
		this.EL.caption.setStyles({	width: this.width });
		
		//position it
		this.positionWindow();
	},

	openMobileModal: function(options){

		var modal = this;

		if(options.text && jQuery.trim(options.text).length){

			console.log("you provided the content for the modal");
			console.log(options.text);

			jQuery("body").addClass("modal-show");

			jQuery(".ui-page-active #mobileModal").html(options.text);

		}

		else if (options.url) {

			console.log("alright, fire off the ajax call");

			jQuery.get(options.url, function(data){

				jQuery("body").addClass("modal-show");

				jQuery(".ui-page-active #mobileModal").html(data);

			});


		}

		else {

			console.log("not sure what content you want to use");

		}

	},

	open: function(options){

		// debugger;

		var isMobileLoad = jQuery("html").hasClass("mobile-device");


		if(isMobileLoad){

			this.openMobileModal(options);

		}

		else {

			var tmp_height = 0, tmp_width = 0, self = this, show = true;

			var iFrameProps = {};

			if (options.isAlertType) {

				// Alert modals will be dynamically sized based on content.
				this.EL.container.addClass("alert-modal");
				options.width = options.width ? options.width : "auto";
				options.height = options.height ? options.height : "auto";

			}
			
			//options has the following parameters
			//option.width = int							-- required
			//options.height = int							-- required
			//options.caption = html text	for teh caption		-- required
			//options.text = html text for the window			
			//options.url = URL for content of the window - will make an AJAX call if iframe is not set to true
			//options.iframe = Bool - false by default
			//options.close = Bool - true by default - hides the close button
			//options.close = Bool - true by default - hides the close button
			
			//**** handle the options *******
			if (options.width == undefined) { return false; }
			if (options.height == undefined) { return false; }
			this.width = options.width; this.height = options.height;
			if (options.iframe == undefined) { options.iframe = false; }
			if (options.close == undefined) { options.close = true; }
			if (options.overlayClose == undefined) { options.overlayClose = true; }
			
			
			//********** handle dimensions and positions ********
			this.EL.caption.setStyle("width", options.width);
			
			this.EL.container.setStyles({ width: options.width, height: options.height });
			// this.EL.container.setStyles({ width: this.width, height: this.height });
			this.EL.content.setStyles({ height: this.height, width: this.width });
			this.positionWindow();
			
			//**** set caption content ****
			if (options.caption) this.EL.caption.setHTML(options.caption);
			
			//**** handle content ****
			if (options.iframe){
				if (options.url){

					iFrameProps = {
						"id": "modalIframe",
						"frameborder": "0",
						"width": "100%",
						"class": this.classPrefix + "iframe",
						"src": options.url
					};

					if (options.isAlertType) {

						iFrameProps.scrolling = "no";

					} else {

						iFrameProps.height = this.EL.content.getStyle("height").toInt();

					}

					// this.EL.iframe = new Element("iframe", {"id": "modalIframe", "frameborder": "0", "width": "100%", "height": this.EL.content.getStyle("height").toInt(), "class": this.classPrefix + "iframe", "src": options.url  }).injectInside(this.EL.content);
					this.EL.iframe = new Element("iframe", iFrameProps).injectInside(this.EL.content);
					
					this.EL.iframe.addClass(this.classPrefix + "iframeLoader");
					
					//.addClass(this.classPrefix + "iframe");
					//this.EL.iframe.setStyles({
					//	width: "inherit",
					//	height: this.EL.content.getStyle("height").toInt()
					//});
					//this.EL.iframe.src = options.url;

					this.EL.iframe.addEvent("load", function(event){
						this.removeClass(this.classPrefix + "iframeLoader");
					});
				} else {
					show = false;
				}
			} else {
				if (options.text){
					this.EL.content.setHTML(options.text);
				} else {
					show = false;
				}
			}
			
			//close button option
			if (!options.close){
				this.EL.closeButton.setStyle("visibility", "hidden");
			} else {
				this.EL.closeButton.setStyle("visibility", "visible");
			}
			//overlay click close option
			if (options.overlayClose){
				this.EL.overlay.addEvent("click", self.close.bind(self));
				//store event for garbage collection
				PBUtil.garbage.store ('event', self.moduleName, this.EL.overlay, 'click', self.close); 
			} else {
				this.EL.overlay.removeEvent("click", self.close.bind(self));
			}
			
			if (show) this.show();


		}

		
	},
	close: function(){

		var isMobileLoad = jQuery("html").hasClass("mobile-device");


		if(isMobileLoad){

			jQuery("body").removeClass("modal-show");
			jQuery.removeCookie("BYPASSMOBILE");

		}

		else {

			this.EL.caption.setHTML("");
			if (this.EL.iframe) this.EL.iframe.remove(); this.EL.iframe = null;
			this.EL.content.setHTML("");
			this.EL.container.removeClass("alert-modal");
			this.EL.container.setStyle("display", "none");
			this.EL.overlay.setStyle("display", "none");

		}

	},
	show: function(){
		this.EL.container.setStyle("display", "block");
		this.EL.overlay.setStyle("display", "block");
	},
	updateContent: function(text){
		this.EL.content.setHTML(text);
	},
	positionWindow: function(){
		/*var tmp_top = 0, tmp_left = 0;
		
		//handle overlay dimensions
		this.EL.overlay.setStyles({ height: window.getScrollHeight() });
		
		//handle container positioning
		tmp_top = 20; tmp_left = 20;
		if (tmp_left < (window.getWidth() - this.width)/2){ tmp_left = (window.getWidth() - this.width)/2; }
		if (tmp_top < (window.getHeight() - this.height)/2){ tmp_top = (window.getHeight() - this.height)/2; }
		this.EL.container.setStyles({ top: tmp_top, left: tmp_left });*/
	},
	changeDimension: function(w, h){
		var currW = 0, currH = 0, currL, currT;
		currW = this.EL.container.getStyle("width").toInt();
		currH = this.EL.container.getStyle("height").toInt();
		currL = this.EL.container.getStyle("left").toInt();
		currT = this.EL.container.getStyle("top").toInt();
		
		if (w){
			this.EL.container.setStyle('width', w);	
			this.EL.caption.setStyle( 'width', w);
			this.width = w;
		}
		if (h){
			this.EL.container.setStyle('height', h);	
			this.height = h;
		}
		this.EL.container.setStyle('display', 'block');	
		if (this.EL.iframe){
			this.EL.iframe.setStyle( 'height', this.height);
		}
		this.EL.content.setStyles({ height: this.height, width: this.width});
		this.positionWindow();
	},
	unload: function(){
		var self = this;
		self.log("Executing the 'unload' method");
		
		//******** Unload local DOMs
		for (var key in this.EL){
			this.EL[key] = null;
		}
		
		//******* Unload global DOMs and EVENTs
		PBUtil.garbage.dispose ('all', self.moduleName)
		
		//***** Unload the class
		var moduleName = this.moduleName;
		for (var key in this){
			self.log(key + " --- " + $type(this[key]) + " --- " + this[key]);
			if ($type(this[key]) == 'object'){
				delete this[key];
				self.log("DELETED object: " + key );
			} else if ($type(this[key]) == 'string' || $type(this[key]) == 'number' || $type(this[key]) == 'collection' || $type(this[key]) == 'regexp' || $type(this[key]) == 'function'){
				if (key != 'log'){
					this[key] = null;
					delete this[key];
					self.log("DELETED: " + key );
				} 
			} else {
				self.log("****** NOT DELETED: " + key )	
			}
		}
		for (var key in this){
				self.log("DELETED Left Over: " + key  + " --- " + $type(this[key]) + " --- " + this[key]);
				this[key] = null;
				delete this[key];
		}
		
		this.log = null;
		PBUtil.log("Deleting the LOG method. Check: " + this.log);
		
		if (!window.ie) delete this;
		
		PBUtil.module[moduleName] = null;
		delete PBUtil.module[moduleName];
		
		PBUtil.log("Deleting the PBUtil store. Check: " + PBUtil.module[moduleName]);
		
		moduleName = null;
	},
	log: function(txt){
		if (window.console && this.showLog){
			window.console.log("Class " + this.moduleName + ": " + txt);
		}
	}
});
modalWindow.implement(new Options);

PBUtil.module.Lightbox = null;

window.addEvent('domready', function(){
	PBUtil.module.Lightbox = new modalWindow({classPrefix: 'store_'});	
});
