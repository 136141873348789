var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);
var domCache = require('dom-cache');

var setupDom = function() {

	domCache.searchInput = $('#txt_search_keyword');
	domCache.form = $('#theform');

};

var addEventHandlers = function() {

	// Event access page entry
	domCache.document.on('keypress', '#password_text', function(e){
		if(e.which === 13){
			domCache.form.submit();
		}
	});

	// Event search  
	domCache.document.on('keypress', '#search_words', function(e){
		if(e.which === 13){
			domCache.form.submit();
		}
	});
	 
	// Main search initiation 
	domCache.document.on('keypress', '#txt_search_keyword', function(e) {
		if(e.which === 13) {
			e.preventDefault();
			domCache.form.attr('method','get');
			domCache.form.submit();
		}
	});

	domCache.document.on('keyup', '#txt_search_keyword', function() {

		var $input = $(this);
		var $wrapper = $input.parents('.txt-search-keyword-wrapper');

		if($input.val() === '') {
			$wrapper.addClass('empty');
		}

		else {
			$wrapper.removeClass('empty');
		}

	});

	domCache.document.on('click', '.icon-search-clear-wrapper', function() {
		clearSearchInput();
	});

};

var clearSearchInput = function() {

	domCache.searchInput.val('');
	domCache
		.searchInput
		.closest('.txt-search-keyword-wrapper')
		.addClass('empty');

};

var modifySearchFormMarkup = function() {

	var $wrapper = $('<div class="txt-search-keyword-wrapper"/>');
	var xMarkup = '<span class="icon-search-clear-wrapper"><i class="icon-search-clear mobile-icon mobile-icon--times-circle-o"></i></span>';

	domCache.searchInput.wrap($wrapper);

	// must requery the dom now that we've wrapped the input
	$('.txt-search-keyword-wrapper').append(xMarkup);

};


var init = function() {

	setupDom();

	addEventHandlers();

	modifySearchFormMarkup();

	console.log('init dat.');

};

module.exports = {
	init: init
};