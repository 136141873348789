'use strict';

// Any modules that need to eventually be exposed to legacy js will be stored here.
var exports = {};
var backToTop = require('back-to-top');
var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

// Get custom vendor build modules.
require('mobile-core-modernizr');
require('mobile-core-jquery-ui');

// Get generic vendor modules.
require('jquery-validate');
require('jquery-cookie');

// Get generic custom modules.
require('image-protect');

// Get mobile specific custom js.
exports.mobile = require('mobile-core-mobile');
exports.slideshow = require('mobile-core-slideshow');

// since mobile build is pulled in within header
// (to support legacy store considerations), we need to
// hold off on initing the backToTop module until the dom
// is ready
$(function() {

	backToTop.init({
		icon: 'mobile-icon mobile-icon--arrow-up'
	});

});

module.exports = exports;
