// JavaScript Document
/**
 * Declare some global objects needed for sitewide operation
 */

//the 'PBUtil' object will make global references more standardized
var PBUtil = {
	module: {},					//object to store module instances
	globalModules: ['Lightbox', 'dropMenu', 'stretchLayout'],	//the global modules that should not be unloaded with just page content
	garbage: {					//object to store DOM and EVENTS for garbage collection and realted functions
		storage: {}
	}
};

/**
 * These are URL values sent to the server to specify which modules should be loaded at the front-end
 * using the iframe that is responsible for loading content and initiating JS modules
 */
PBUtil.URLloader = {};
PBUtil.URLloader.gallery = "load=event_gallery";
PBUtil.URLloader.soloImage = "load=event_gallery";
PBUtil.URLloader.detailImage = "load=event_image_detail";
PBUtil.URLloader.slideShow = "load=event_gallery";
PBUtil.URLloader.magnify = "load=event_gallery";
PBUtil.URLloader.leftCompare = "load=event_gallery";
PBUtil.URLloader.rightCompare = "load=event_gallery";

/* ======================================================*/
/*  			Garbage Management & Collection 		 */
/* ======================================================*/

/**
 * Function to store DOM elements and events for garbage collection.
 * This function is used in the JS module to store DOMs so they can be disposed when
 * modules' "unload" methods are called, when window reloads or when
 * the PBUtil.garbage.unloadModule methos is called.
 *
 * This method is Private - DO NOT CALL this method from your JS, if unless used in the right
 * context
 *
 * @scope		Private
 *
 * @params 		type 		String, Required. 'event' or 'dom'.
 * @params		scope 		String, Required. Scope name - i.e. the module name
 * @params		el			DOM Element, Required. The target for event or the DOM itself
 * @params		eventType	String, Required. Event type - 'click', 'mouseover', etc.
 * @params		functionName pass the function being added as the event action
 */
PBUtil.garbage.store = function(type, scope, el, eventType, functionName){
	if (!PBUtil.garbage.storage[scope]){
		PBUtil.garbage.storage[scope] = {};
		PBUtil.garbage.storage[scope].DOM = [];
		PBUtil.garbage.storage[scope].EVENT = [];
	}

	//handle DOM
	if (type == 'dom'){
		var domLength = PBUtil.garbage.storage[scope].DOM.length;
		PBUtil.garbage.storage[scope].DOM[domLength] = el;


	//handle EVENTS
	} else if (type == 'event'){
		//PBUtil.log("Storing EVENT. EL: " + el + ", eventType: " + eventType +", function: "+ functionName);
		var eventLength = PBUtil.garbage.storage[scope].EVENT.length;
		PBUtil.garbage.storage[scope].EVENT[eventLength] = {};
		PBUtil.garbage.storage[scope].EVENT[eventLength].el = el;
		PBUtil.garbage.storage[scope].EVENT[eventLength].eventType = eventType;
		PBUtil.garbage.storage[scope].EVENT[eventLength].functionName = functionName;
	}

};
/**
 * Function to collect (dispose) DOM elements and events for garbage collection.
 * This method is used by JS modules for garbage collection.
 *
 * This method is Private - DO NOT CALL this method from your JS, if unless used in the right
 * context
 *
 * @scope		Private
 *
 * @params 		type 		String, Required. 'event', 'dom' or 'all'.
 * @params		scope 		String, Required. Scope name - i.e. the module name
 */
PBUtil.garbage.dispose = function(type, scope){
	PBUtil.log("Start disposing for Scope: " + scope);
	if (!$defined(PBUtil.garbage.storage[scope])) return;

	//handle EVENTs
	if (type == 'event' || type == 'all'){
		if ($defined(PBUtil.garbage.storage[scope].EVENT)){
			for (var i=0;i<PBUtil.garbage.storage[scope].EVENT.length;i++){
				PBUtil.log("Disposing EVENT. Scope: " + scope + ", EL: " + PBUtil.garbage.storage[scope].EVENT[i].el.id + ", EVENTS: " + PBUtil.garbage.storage[scope].EVENT[i].eventType + ", Function: " + PBUtil.garbage.storage[scope].EVENT[i].functionName);
				PBUtil.garbage.storage[scope].EVENT[i].el.removeEvent(PBUtil.garbage.storage[scope].EVENT[i].eventType, PBUtil.garbage.storage[scope].EVENT[i].functionName)
			}
			PBUtil.garbage.storage[scope].EVENT = null;
		}
	}

	//handle DOMs
	if (type == 'dom' || type == 'all'){
		if ($defined(PBUtil.garbage.storage[scope].DOM)){
			if (PBUtil.garbage.storage[scope].DOM){
				for (var i=0;i<PBUtil.garbage.storage[scope].DOM.length;i++){
					PBUtil.log("Disposing DOM. Scope: " + scope + ", EL: " + PBUtil.garbage.storage[scope].DOM[i]);
					if (PBUtil.garbage.storage[scope].DOM[i] != window) PBUtil.garbage.storage[scope].DOM[i] = null;
				}
				PBUtil.garbage.storage[scope].DOM = null;
			}
		}
	}

	if (type == 'all'){
		delete PBUtil.garbage.storage[scope];
		//PBUtil.log("Disposing the Scope: " + scope + ", Check Value: " + PBUtil.garbage.storage[scope]);
	}

};
/**
 * Function to collect (dispose) DOM elements and events for garbage collection
 *
 * This method is Public - you can call this method anytime to unload the memory
 * of a local module (i.e. gallery, slideshow, etc.)
 * To unload all memory supply scope='all', otherwise supply 'local' or leave empty.
 *
 * @scope		Public
 *
 * @params		scope 		String, Required. 'all', 'local' or specific module name i.e. 'eventGallery'. Default is 'local'.
 */
PBUtil.garbage.unloadModule = function(scope){
	if (!$defined(scope)) scope = 'local';

	if (scope == 'local') {
		//skip the global modules
		for(var key in PBUtil.module){
			if (!PBUtil.globalModules.contains(key) && PBUtil.module[key]){
				PBUtil.module[key].unload();
				PBUtil.module[key] = null;
			}
		}
	} else if (scope == 'all') {
		for(var key in PBUtil.module){
			if (PBUtil.module[key]){
				PBUtil.module[key].unload();
				PBUtil.module[key] = null;
			}
		}
	} else {
		if (PBUtil.module[scope]){
			PBUtil.module[scope].unload();
			PBUtil.module[scope] = null;
		}
	}
}


/* ======================================================*/
/*  					Extending Natives				 */
/* ======================================================*/

/**
 * Extending the Element class. Following code extends mootools' functionality
 * to add additional DOM features.
 */
Element.extend({
	/**
	 * This method will return the inner width or height - considering margin and padding
	 */
	getInnerDimension: function(side){

		var coords = this.getCoordinates(), val = {}, innerSpace;

		if (!side || side == 'width'){
			innerSpace = coords.width ;//- this.getStyle('margin-left').toInt() - this.getStyle('margin-right').toInt();
			innerSpace -= this.getStyle('padding-left').toInt() + this.getStyle('padding-right').toInt();
			//innerSpace -= this.getStyle('border-left-width').toInt() + this.getStyle('border-right-width').toInt();

			val.width = innerSpace;
		}

		if (!side || side == 'height'){
			innerSpace = 0;
			innerSpace = coords.height ;//- this.getStyle('margin-top').toInt() - this.getStyle('margin-bottom').toInt();
			innerSpace -= this.getStyle('padding-top').toInt() + this.getStyle('padding-bottom').toInt();
			//innerSpace -= this.getStyle('border-top-width').toInt() + this.getStyle('border-bottom-width').toInt();

			val.height = innerSpace;
		}

		return val;
	},
	/**
	 * This method will return the outer width or height   - considering border
	 */
	getOuterDimension: function(side){

		var coords = this.getCoordinates(), val = {}, outerSpace;

		if (!side || side == 'width'){
			outerSpace = coords.width +  this.getStyle('margin-left').toInt() + this.getStyle('margin-right').toInt(); //this.getStyle('border-left-width').toInt() + this.getStyle('border-right-width').toInt() +
			val.width = outerSpace;
		}

		if (!side || side == 'height'){
			outerSpace = 0;
			outerSpace = coords.height +  this.getStyle('margin-top').toInt() + this.getStyle('margin-bottom').toInt(); //this.getStyle('border-top-width').toInt() + this.getStyle('border-bottom-width').toInt() +
			val.height = outerSpace;
		}

		return val;
	},
	hide: function(){
		this.setStyle("display", "none");
	},
	show: function(display){
		(display) ? this.setStyle("display", display) : this.setStyle("display", "block");
	},
	visible: function(){
		this.setStyle("visibility", "visible");
	},
	invisible: function(){
		this.setStyle("visibility", "hidden");
	}

});

/* ======================================================*/
/*  					Misc Methods					 */
/* ======================================================*/


/**
 * Load HTML content into a DIV tag. Use this method to load content to any HTML ELEMENT with an ID
 *
 * @scope		Public
 *
 * @params		target 		String, Required. ID of hte target ELEMENT (div, span, input or other HTML ELEMENT)
 * @params		html		String, Required. The HTML content to be inserted
 * @params		unload		Boolean, Optional. Whether to unload local modules when loading new content
 */
PBUtil.loadContent = function(target, html, unload){
	//unload previous modules

	if (unload) {
		PBUtil.garbage.unloadModule();
	}

	//insert content
	if ($type(target) != 'element'){
		target = $(target);
	}
	target.setHTML(html);

	//resize the window
	if (PBUtil.module.stretchLayout){
		PBUtil.module.stretchLayout.resizeAction();
	}
}


/**
 * Function for updating the JSON data stored in the 'eventdata' iframe. Two updates are
 * currently coded in - updating favorite images and selected image.
 *
 * @scope		Public
 *
 * @params		type		String, Required. the purpose i.e. 'update_selected_item', 'update_favorite'
 * @params		options		Object, Required. supply id of image and gallery in the following format.
 *							For selected image, options = {id: x, parent_id: y}
 *							For favorite, options = {id: x, favorite: 1 or 0 } //1 for yes, 0 for no
 * @params		print_log	String, Optional. Prints the status messages in window console (i.e. firebug)
 */
PBUtil.updateEventJSON = function(type, options, print_log){

	var data_iframe = $('ifr_eventdata');

	if (print_log) PBUtil.log("Executing update JSON for - " + type);

	if ($type(options) == 'object'){
		if (type == 'update_selected_item'){
			//options should include {
			//	"id":23
			//}
			if (data_iframe.contentWindow.json_event_data){
				data_iframe.contentWindow.json_event_data.selected_item.id = options.id;
				data_iframe.contentWindow.json_event_data.selected_item.parent_id = options.parent_id;
				if (print_log) PBUtil.log("JSON update type '" + type + "' was successful");
			} else {
				if (print_log) PBUtil.log("JSON Data Update: ERROR. Could not find the JSON object in target iframe.");
			}
		}

		if (type == 'update_favorite'){
			//options should include {
			//	"id":23,
			//	"favorite": 0 || 1
			//}
			if (data_iframe.contentWindow.json_event_data){
				for (var i=0;i<data_iframe.contentWindow.json_event_data.items.length;i++){
					//window.console.log(data_iframe.contentWindow.json_event_data.items[i].id + " == " + options.id);
					if (data_iframe.contentWindow.json_event_data.items[i].id == options.id){
						data_iframe.contentWindow.json_event_data.items[i].favorite = options.favorite;
						if (print_log) PBUtil.log("JSON update type '" + type + "' was successful");
						break;
					}
				}
			} else {
				if (print_log) PBUtil.log("JSON Data Update: ERROR. Could not find the JSON object in target iframe.");
			}
		}
	} else {
		if (print_log) PBUtil.log("JSON Data Update: ERROR. Wrong 'options' supplied for update type '" + type + "'.");
	}

	data_iframe = null;
}

PBUtil.log = function(text){
	if (window.console)	{
		//window.console.log("LOG: " + text)	;
	}
}


/* added by KC on 2/14/11
 * using it for ajax request cache busting
 */
PBUtil.randString = function(len) {

	var out = [];
	var chars = ['a','b','c','d','e','f','g','h','i','j','k','m','n','o','p','q','r','s','t','u','v','w','x','y','z','A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z','_','-','0','2','3','4','5','6','7','8','9'];
	var count = chars.length;

	for (var i = 0; i <= len; i++) {
		out.push(chars[Math.floor(Math.random() * count)]);
	}

	return out.join('');
};

/* added by KC on 2/18
 * using it for simple alerts
 */
PBUtil.simpleAlert = function(width, height, msg) {

	msg = msg.replace(/ /g,"|");

	var request = new Ajax(
		'/store/includes/alert_simple.cfm' + serverVars.link_prefix + 'cb=' + PBUtil.randString(40) + '&msg=' + encodeURIComponent(msg),
		{
			onComplete: function(r) {
				PBUtil.module.Lightbox.open({
					width: width,
					height: height,
					text: r.trim(),
					caption: '',
					iframe: false,
					close: true
				});
			}
		}
	).request();
};

PBUtil.submitOnEnter = function(event,target,formAction){
	if (event.keyCode == 13) {
		top.changeform(target,formAction);
	}
}

/* added by KC on 4/03/2012
 * utility for determining whether to load black or white icon images for gallery controls, slideshows, etc.
 * (helps ensure proper contrast on dynamically colored backgrounds)
*/
PBUtil.blackOrWhite = function(color, blackOffset, whiteOffset) { // color can be passed with or without hash symbol #ffffff or ffffff, the black and white offsets allow for fine-tuning the caller's needs

	var blackOffset = typeof blackOffset !== 'undefined' ? blackOffset : 0;
	var whiteOffset = typeof whiteOffset !== 'undefined' ? whiteOffset : 0;

	function diff(color1, color2) {

		function toRGB(hex) {

			hex = hex.replace(/#/g, '');

			return [
				parseInt(hex.substring(0, 2), 16),
				parseInt(hex.substring(2, 4), 16),
				parseInt(hex.substring(4, 6), 16)
			];

		}

		// most of the time, jQuery will return a css color as rgb(...) so it will need
		// to be converted to hex; seems odd to convert from rgb string to hex and
		// back to rgb, but the data types are different and I already had toRGB flow working
		function toHex(rgb) {

			var parts = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);

			delete (parts[0]);

			for (var i = 1; i <= 3; ++i) {

				parts[i] = parseInt(parts[i]).toString(16);

				if (parts[i].length == 1) {
					parts[i] = '0' + parts[i];
				}

			}

			return '#' + parts.join('').toUpperCase();

		}

		if (color1.indexOf('rgb(') > -1) {
			color1 = toHex(color1);
		}

		if (color2.indexOf('rgb(') > -1) {
			color2 = toHex(color2);
		}

		color1 = toRGB(color1);
		color2 = toRGB(color2);

		return Math.max(color1[0], color2[0]) - Math.min(color1[0], color2[0]) + Math.max(color1[1], color2[1]) - Math.min(color1[1], color2[1]) + Math.max(color1[2], color2[2]) - Math.min(color1[2], color2[2]);

	}

	if ( (diff('000000', color) + blackOffset) > (diff('ffffff', color) + whiteOffset) ){
		return 'black';
	} else {
		return 'white';
	}

};

/* added by BF on 4/03/2012
 * using Kev's super cool blackOrWhite utility function for generic checking of whether a hex value is a light or dark color
*/
PBUtil.isLight = function(hex, blackOffset, whiteOffset){
	var result = PBUtil.blackOrWhite(hex, blackOffset, whiteOffset);
	result = result === "black" ? true : false;
	return result;
};

/* added by BF on 4/03/2012
 * using Kev's super cool blackOrWhite utility function for generic checking of whether a hex value is a light or dark color
*/
PBUtil.isDark = function(hex, blackOffset, whiteOffset){
	return !PBUtil.isLight(hex, blackOffset, whiteOffset);
};

/* added by BF on 4/09/2012
 * pass in a hex value and a shade value to get a lighter or darker shade of that color.
 * usage: PBUtil.shadeColor('#ffcccc', -.3); // 30% darker
*/
PBUtil.shadeColor = function(hex, shade){
	// console.log("color passed into shader: " + hex);

	hex = String(hex).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6) {
        hex = hex[0]+hex[0]+hex[1]+hex[1]+hex[2]+hex[2];
    }
    shade = shade || 0;
    // convert to decimal and change luminosity
    var rgb = "#", c, i;
    for (i = 0; i < 3; i++) {
        c = parseInt(hex.substr(i*2,2), 16);
        c = Math.round(Math.min(Math.max(0, c + (c * shade)), 255)).toString(16);
        rgb += ("00"+c).substr(c.length);
    }

	// console.log("color returned from shader: " + rgb);
    return rgb;

}

/* added by BF on 4/10/2012
 * utility to convert hex color to rgb equivalent. this version returns separate RGB values, for RBG as string call, hexToRgbStr below
*/
PBUtil.hexToRgb = function(hex){
	if (hex[0] == "#"){
		hex=hex.substr(1);
	}
	if (hex.length==3){
		var temp=hex; hex='';
		temp = /^([a-f0-9])([a-f0-9])([a-f0-9])$/i.exec(temp).slice(1);
		for (var i=0;i<3;i++) hex+=temp[i]+temp[i];
	}
	var triplets = /^([a-f0-9]{2})([a-f0-9]{2})([a-f0-9]{2})$/i.exec(hex).slice(1);
	return {
		red: parseInt(triplets[0],16),
		green: parseInt(triplets[1],16),
		blue: parseInt(triplets[2],16)
	}
}

/* added by BF on 4/10/2012
 * converts hex color to rgb equivalent and returns rgb string
*/
PBUtil.hexToRgbStr = function(hex){
	var rgb = PBUtil.hexToRgb(hex);
	return "rgb(" + rgb.red + ", " + rgb.green + ", " + rgb.blue + ")";
}

/* added by BF on 4/10/2012
 * converts hex color to rgba equivalent, allowing you to pass in an opacity for the alpha value
*/
PBUtil.hexToRgbaStr = function(hex, opacity){
	var rgb = PBUtil.hexToRgb(hex);
	return "rgba(" + rgb.red + ", " + rgb.green + ", " + rgb.blue + ", " + opacity + ")";
}

/* added by BF on 4/10/2012
 * converts rgb color to hex equivalent
*/
PBUtil.rgbToHex = function(r, g, b) {
    return "#" + ((1 << 24) + (parseInt(r) << 16) + (parseInt(g) << 8) + parseInt(b)).toString(16).slice(1);
}

/* added by BF on 4/11/2012
 * lets you know version of internet explorer user has
 * usage: if(PBUtil.ieVersion > 9) alert("not good enough. switch to chrome.");
*/
PBUtil.ieVersion = (function(){

    var undef,
        v = 3,
        div = document.createElement('div'),
        all = div.getElementsByTagName('i');

    while (
        div.innerHTML = '<!--[if gt IE ' + (++v) + ']><i></i><![endif]-->',
        all[0]
    );

    return v > 4 ? v : undef;

}());

PBUtil.isModernBrowser = function(){
    var ieVersion = PBUtil.ieVersion;
	var isModern = false;
	if(!PBUtil.isIE() || ieVersion > 8){
		isModern = true;
	}
	return isModern;
};

PBUtil.isIE = function(){
	var isIE = true;
	if(PBUtil.ieVersion === undefined){
		isIE = false;
	}
	return isIE;
};

PBUtil.isNumeric = function isNumeric(value) {
  return !isNaN(parseFloat(value)) && isFinite(value);
}


//Attach unload to the window event
window.addEvent("beforeunload", function() {
	PBUtil.garbage.unloadModule('all')
});

/* window.addEvent('domready', function() {

	// social icon mouseovers
	$$('.footer_social img').each(function(img) {
		var initialSrc = img.getProperty('src');
		var mouseoverSrc = '/images/' + img.getProperty('alt').replace('_bw', '');
		img.addEvent('mouseenter', function() {
			img.setProperty('src', mouseoverSrc);
		});
		img.addEvent('mouseleave', function() {
			img.setProperty('src', initialSrc);
		});
	});

}); */
