'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var dom = {

	document: $(document),
	mainForm: $('#theform'),
	modalContainer: $('#mobileModal')

};

var redirect = function(uri) {

	window.location = uri;

};

var addEventHandlers = function() {

	dom.document.off('.store');

	dom.document.on('click.store', '.checkout-link-mobile', function(e){

		e.preventDefault();

		dom.modalContainer.css('opacity', '0');

		redirect('?load=cart&bypassMobile=true');

	});

};

var init = function(){

	addEventHandlers();

};

$(init);