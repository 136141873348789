'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var api = {};

var state = {};

var dom = {
	document: $(document)
};

var globals = mobile.globals;

var setupInitialState = function(){

	state.date = new Date();

};

var addEventHandlers = function(){

	var date = state.date;

	dom.document.off('.calendar');

	dom.document.on('vclick.calendar', '.booked-date', function(){

		$.mobile.changePage('/?load=message&msgKey=booked&steps=-1');

	});

	$('.ui-page-active #datePicker').datepicker({

		minDate: [(date.getMonth() + 1), date.getDate(), date.getFullYear()].join('/'),

		onSelect: function(dateText, $picker){

			var isAcceptingBookings = $(this).attr('data-is-accepting-bookings');

			if(isAcceptingBookings === 'true'){
				$.mobile.changePage('/?load=calendar_book&page=' + mobile.globals.pageId + '&date=' + encodeURIComponent(dateText) + '&' + globals.previewMode);
			}

		},

		prevText: '',
		nextText: '',

		beforeShowDay: function(date) {

			if (isDateBooked(date)) {
				return [false, 'booked-date'];
			} else {
				return [true, ''];
			}

		}

	});

};

var isDateBooked = function(date){
	return $.inArray($.datepicker.formatDate('mm/dd/yy', date), globals.bookedDates) >= 0;
};

api.init = function(){

	setupInitialState();

	addEventHandlers();

};

module.exports = api;