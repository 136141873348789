'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var dom;

var globals = window.mobile.globals;

var getPlatform = function() {

	return typeof window.mobile.globals.storePath === 'undefined' ? 'portfolio' : 'store';

};

var setupDom = function() {

	dom = {};
	dom.document = $(document);
	dom.menu = $('#menu');
	dom.body = $('body');
	dom.topMenuLink = $('.mobile-top-menu__menu-link');
	dom.menuPanel = $('#menuPanel');

};

var addEventHandlers = function(){

	console.log(dom.document);
	console.log(dom.document.off);

	dom.document.off('.menu');

	dom.document.on('vclick.menu', '[data-is-dropdown="true"]', function(e){

		var $a = $(this);
		var isExpanded = $a.attr('data-expanded') === 'true';

		$a.parents('li').find('.subMenu').toggle();

		$('#menuPanel').removeClass('ui-panel-fixed');

		$a.attr('data-expanded', !isExpanded);

		e.preventDefault();

	});

	dom.document.on('panelopen.menu', function(){
		$('.biz-menu-btn .mobile-icon--bars').attr('class', 'mobile-icon mobile-icon--times');
	});

	dom.document.on('click.menu', '.mobile-side-menu__cart-link,.mobile-top-menu__cart-link', function(){
		window.location = window.mobile.globals.storePath + '?load=cart';
	});

	dom.document.on('panelclose.menu', function(){
		$('.biz-menu-btn .mobile-icon--times').attr('class', 'mobile-icon mobile-icon--bars');
	});

	dom.document.on('vclick.menu', '.topLink[data-is-dropdown=false][data-is-external=false]:not(#fullSiteLink),.subLink[data-is-external=false]', function(e){ // clicking an 'endpoint'
		$('.biz-menu-btn .mobile-icon--times').attr('class', 'mobile-icon mobile-icon--bars');
		$.mobile.changePage($(this).attr('href'));
		e.preventDefault();
	});

	dom.document.on('vclick.menu', '.topLink[target="_top"]:not(#fullSiteLink),.subLink[target="_top"]', function(){ // same page anchor

		var $el = $(this);

		if (getPlatform() === 'store') {

			$("#menuPanel").panel("close");

			$el.closest('ul').find('li').removeClass('selected');

			$el.closest('li').addClass('selected');

		}

	});

	dom.document.on('vclick.menu', 'a[data-is-external=true]', function(e){
		window.open($(this).attr('data-external-url'));
		e.preventDefault();
	});

	dom.topMenuLink.on('click', function() {

		// this stuff should only apply for the top_menu mobile layout
		dom.body.toggleClass('mobile-top-menu__overflow-hidden');
		dom.menuPanel.toggleClass('ui-panel-closed ui-panel-open');
		
		if (dom.topMenuLink.text() === 'menu') {
			dom.topMenuLink.text('close');
		}

		else {
			dom.topMenuLink.text('menu');
		}

	});

	dom.document.on('ready', function() {
	
		// this stuff needs to be in DOM ready
		// since jquerymobile is apparently also doing some
		// conflicting stuff related to the "menupanel"
		
		if (globals.layout === 'top_menu') {

			dom.menuPanel.removeClass('ui-panel-animate');

		}

	});

};

var updateSelectedMenuItem = function(){

	var urlArr = window.location.href.split('/'),
		friendlyStr = urlArr[urlArr.length - 1],
		friendlyArr = friendlyStr.split('?'),
		friendlyName = friendlyArr[0],
		selector = '.friendly-' + friendlyName;

	if(friendlyName === ''){
		selector = '.page-' + globals.homePageId;
	}

	dom.menu.find('li').removeClass('selected');

	$(selector).parents('li').addClass('selected');

};

var init = function(){

	setupDom();

	addEventHandlers();

};

module.exports = {
	init: init,
	updateSelectedMenuItem: updateSelectedMenuItem
};

