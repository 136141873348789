'use strict';

// Get the core js for the mobile app.
var mobileCore = require('mobile-core');
var search = require('./search');
var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

// Get store specific js.
mobileCore.checkout = require('mobile-store-checkout');
mobileCore.pageBuilderWidgetFactory = require('./page-builder-widget-factory');

// since mobile build is pulled in within header
// (to support legacy store considerations), we need to
// hold off on initing the backToTop module until the dom
// is ready
$(function() {

	search.init();

});

// Expose new browserify modules to legacy js.
module.exports = mobileCore;
